//Dimensions for device media queries and layout padding
const dimensions = {
  //pixels
  maxwidthDesktop: "1425px",
  maxwidthTablet: "768px",
  maxwidthMobile: "600px",

  //ems
  paddingVerticalDesktop: "3vh",
  homeAlignLeft:"4vw",
  homeVerticalMargin: "3vh",
  projectAlignLeft:"10.75vw",
  closeAlignCenter:"5.375vw",

  headerMargin: "12px"
}

export default dimensions
